body {
  background-color: black;
}

h1 {
  font-family: arial;
  font-size: 80px;
}

.middle {
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 1500ms linear;
}

.fade2-appear {
  opacity: 0;
  z-index: 1;
}
.fade2-appear.fade2-appear-active {
    opacity: 1;
    transition: opacity 1000ms linear 300ms;
}