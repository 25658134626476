.social {
    font-size: 35px;
    display: flex;
    justify-content: center;
}

.icon {
    margin: 0 10px 0 10px;
}

a {
    color: inherit;
}