body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: black;
}

h1 {
  font-family: arial;
  font-size: 80px;
}

.middle {
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 1500ms linear;
}

.fade2-appear {
  opacity: 0;
  z-index: 1;
}
.fade2-appear.fade2-appear-active {
    opacity: 1;
    transition: opacity 1000ms linear 300ms;
}
.social {
    font-size: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.icon {
    margin: 0 10px 0 10px;
}

a {
    color: inherit;
}
